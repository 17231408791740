import "../../../js/import/slick";
$(document).ready(function(){
  $('.decisions__img').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    prevArrow: '<button type="button" class="slick-prev"><img src="img/arrow_left.png"></button>',
    nextArrow: '<button type="button" class="slick-next"><img src="img/arrow_right.png"></button>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          autoplay: true,
          autoplayspeed: 300,
          dots: false,
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  });

  //Прокрутка мышью (после клика и удержании на стрелке или табе)

  $('.decisions__img').on('wheel', (function(e) {
    e.preventDefault();
  
    if (e.originalEvent.deltaY < 0) {
      $(this).slick('slickNext');
    } else {
      $(this).slick('slickPrev');
    }
  }));

  document.getElementById("slick-slide-control10").textContent = "Prengi Production";
  document.getElementById("slick-slide-control11").textContent = "Prengi FMC";
  document.getElementById("slick-slide-control12").textContent = "Prengi Mallz Retail";
  document.getElementById("slick-slide-control13").textContent = "Prengi Logistic";
  document.getElementById("slick-slide-control14").textContent = "Prengi IT";
  document.getElementById("slick-slide-control15").textContent = "Prengi HR";

  $(window).resize(function(){
    if ($(window).width() <= 767) { 
    
    document.getElementById("slick-slide-control10").textContent = "Production";
    document.getElementById("slick-slide-control11").textContent = "FMC";
    document.getElementById("slick-slide-control12").textContent = "Mallz Retail";
    document.getElementById("slick-slide-control13").textContent = "Logistic";
    document.getElementById("slick-slide-control14").textContent = "IT";
    document.getElementById("slick-slide-control15").textContent = "HR";

    }    
 });

});