import "../../../js/import/slick";
$(document).ready(function(){
    $('.promo__bg').slick({
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: '<button type="button" class="slick-prev"><img src="img/left_mini.png"></button>',
        nextArrow: '<button type="button" class="slick-next"><img src="img/right_mini.png"></button>',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              infinite: true,
              dots: false,
              prevArrow: '<button type="button" class="slick-prev"><img src="img/arrow_left.png"></button>',
              nextArrow: '<button type="button" class="slick-next"><img src="img/arrow_right.png"></button>'
            }
          },
            {
              breakpoint: 992,
              settings: {
                dots: false,
                prevArrow: '<button type="button" class="slick-prev"><img src="img/arrow_left.png"></button>',
                nextArrow: '<button type="button" class="slick-next"><img src="img/arrow_right.png"></button>',
                centerMode: true,
                centerPadding: '40px'
              }
            },
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                dots: false,
                prevArrow: '<button type="button" class="slick-prev"><img src="img/arrow_left.png"></button>',
                nextArrow: '<button type="button" class="slick-next"><img src="img/arrow_right.png"></button>'
              }
            },
            {
              breakpoint: 576,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                dots: false,
                prevArrow: '<button type="button" class="slick-prev"><img src="img/arrow_left.png"></button>',
                nextArrow: '<button type="button" class="slick-next"><img src="img/arrow_right.png"></button>'
              }
            }
          ]
    });

  $('.hamburger').on('click', function() {
    $('.hamburger').toggleClass('hamburger_active');
    $('.menu').toggleClass('menu_active');
  });

});